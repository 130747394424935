@keyframes fade-in {
    from{
        display: none;
        position: fixed;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        background-color: #282828;
    }
    1%{
      display: block;
      opacity: 0;  
    }
    to{
            display: block;
            opacity: 1;
            background-color: #282828;
            position: fixed;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
            color: white;
            
    }
}